import React, { useEffect } from 'react';

import { PATHNAMES, MODALS } from '../utils/constants';

import NavLink from './NavLink';

function BroadcastIndex({ broadcastList, setPathname, setModalOpen, getUserBroadcasts, pathname }) {
    useEffect(() => {
        getUserBroadcasts();
        if (pathname === PATHNAMES.newBroadcast) setModalOpen(MODALS.createBroadcast);
    }, []);

    const hasBroadcasts = broadcastList && !!broadcastList.length;
    return (
        <div>
            <button
                className="mdl-button mdl-button--raised button-blue"
                onClick={() => setModalOpen(MODALS.createBroadcast)}
                style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                Create broadcast
            </button>
            {!hasBroadcasts && (
                <div>
                    No broadcasts found
                </div>
            )}
            {hasBroadcasts && (
                <table style={{ width: "100%" }} className="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
                    <thead>
                        <tr>
                            <th className="mdl-data-table__cell--non-numeric">Broadcast title</th>
                            <th className="mdl-data-table__cell--non-numeric">Audio</th>
                            <th className="mdl-data-table__cell--non-numeric">Tracklist</th>
                            <th className="mdl-data-table__cell--non-numeric">Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        {broadcastList.map(broadcast => (
                            <tr key={`broadcast-${broadcast.id}`}>
                                <td className="mdl-data-table__cell--non-numeric">
                                    <NavLink
                                        setPathname={setPathname}
                                        className="mdl-navigation__link"
                                        href={[PATHNAMES.broadcasts, broadcast.id].join("/")}>
                                        {broadcast.title}
                                    </NavLink>
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {broadcast.hasAudio && (
                                        <span className="material-icons">
                                            check_circle_outline
                                        </span>
                                    )}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {broadcast.hasTracklist && (
                                        <span className="material-icons">
                                            check_circle_outline
                                        </span>
                                    )}
                                </td>
                                <td className="mdl-data-table__cell--non-numeric">
                                    {broadcast.hasImage && (
                                        <span className="material-icons">
                                            check_circle_outline
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default BroadcastIndex;
