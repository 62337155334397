import React, { useState } from 'react';

function ConfirmationModal({ setModalOpen, confirmationAction, ctaText }) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await confirmationAction();
            setModalOpen(null);
        } catch(e) {
            alert(`Something went wrong. ${e.message || ''}`);
        }

        setIsSubmitting(false);
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="edit-modal__title">
                        Are you sure?
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                type="button"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-red button-responsive"
                                disabled={isSubmitting}>
                                {ctaText}
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
