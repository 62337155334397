import React, { useState } from 'react';

import firebase from '../firebase';
import PayoutModalRegion from './PayoutModalRegion';

function PayoutModal({ authUserShows, showAliasSelected, setAuthUserShows, setModalOpen }) {
    const show = authUserShows.find(show => show.showAlias === showAliasSelected);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function onClick() {
        setModalOpen(false);
    }

    function submitForm(payoutDetails, personalDetails) {
        setIsSubmitting(true);
        return firebase.confirmShowPayout(payoutDetails, show.showAlias, personalDetails, show.amountCents)
            .then(() => firebase.getHostShows())
            .then(setAuthUserShows)
            .then(onClick)
            .catch(err => {
                setIsSubmitting(false);
                alert(`Something went wrong. ${err.message}`);
            });
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <h4 className="mdl-typography--title edit-modal__title">
                        Your details
                    </h4>
                    <p>
                        Please provide some personal information for us to generate your invoice.
                    </p>
                    <PayoutModalRegion
                        isSubmitting={isSubmitting}
                        setModalOpen={setModalOpen}
                        submitForm={submitForm} />
                </div>
            </div>
        </div>
    );
}

export default PayoutModal;
