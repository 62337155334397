import React, { useState } from 'react';

import { PATHNAMES, BROADCAST_DETAIL_PATH_REGEX } from '../utils/constants';
import ExportButton from './ExportButton';
import firebase from '../firebase';
import NavLink from './NavLink';
import { NTSLogoIcon } from './Icons';

function NavBar({ authUser, pathname, setPathname, broadcastList, authUserShows }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerClass = isDrawerOpen ? 'is-active' : '';
    const hasShows = !!(authUserShows && authUserShows.length);
    const hasBroadcasts = !!(broadcastList && broadcastList.length);

    function handleDrawerButtonClick() {
        setIsDrawerOpen(isDrawerOpen => !isDrawerOpen);
    }

    function homeHeading() {
        if (authUser.roles.admin) return 'Subscriptions';

        if (authUser.roles.host && authUserShows.length) return 'Payout';

        if (authUser.roles.host && broadcastList.length) return 'Broadcasts';

        return null;
    }

    function pageHeading() {
        if (BROADCAST_DETAIL_PATH_REGEX.test(pathname)) return 'Broadcasts';

        switch(pathname) {
            case PATHNAMES.home:
                return homeHeading();
            case PATHNAMES.devices:
                return 'Devices';
            case PATHNAMES.recommendations:
                return 'Recommendations';
            case PATHNAMES.hosts:
                return 'Hosts';
            case PATHNAMES.payout:
                return 'Payout';
            case PATHNAMES.listenerPicks:
                return 'Listener Picks';
            case PATHNAMES.broadcasts:
                return 'Broadcasts';
            default:
                return null;
        }
    }

    async function handleLogOut() {
        setPathname(PATHNAMES.logout);
        window.history.pushState({}, '', PATHNAMES.logout);
        console.log('logout');
        firebase.signOut();
    }

    function handleHomeClick(e) {
        e.preventDefault();
        setPathname(PATHNAMES.home);
        window.history.pushState({}, '', PATHNAMES.home);
    }

    return (
        <>
            {isDrawerOpen &&
                <div
                    className="mdl-layout__obfuscator is-visible"
                    onClick={handleDrawerButtonClick} />
            }
            <header className="nav-bar-header mdl-layout__header mdl-color--grey-100 mdl-color-text--grey-600">
                <div
                    className="mdl-layout__header-row nav-bar-header__row">
                    <button
                        className="mdl-layout__drawer-button"
                        style={{ paddingTop: '2px' }}
                        onClick={handleDrawerButtonClick}>
                        <i
                            className="material-icons mdl-color-text--grey-600"
                            role="presentation">
                            menu
                        </i>
                    </button>

                    <h2 className="mdl-layout-title">{pageHeading()}</h2>

                    {pathname === PATHNAMES.hosts && <ExportButton />}
                </div>
            </header>
            <div className={`nav-bar-drawer mdl-layout__drawer mdl-color--black ${drawerClass}`}>
                <header className="nav-bar-drawer__header mdl-color-text--blue-grey-50">
                    <a onClick={handleHomeClick} className="nav-bar-drawer__header-home">
                        <NTSLogoIcon />
                        <h1>Desk</h1>
                    </a>
                    <div className="nav-bar-drawer__avatar">
                        <span>{authUser.email}</span>
                        <button className="nav-bar-drawer__header-button" onClick={handleLogOut}>
                            <i className="mdl-color-text--blue-grey-50 material-icons" role="presentation">
                                logout
                            </i>
                        </button>
                    </div>
                </header>
                <nav className="nav-bar-drawer__navigation mdl-navigation">
                    {authUser.roles.admin &&
                        <>
                            <NavLink
                                activePathname={pathname}
                                setPathname={setPathname}
                                className="mdl-navigation__link mdl-color-text--grey-500"
                                href={PATHNAMES.home}
                                closeDrawer={handleDrawerButtonClick}>
                                <i className="material-icons" role="presentation">eco</i>
                                Subscriptions
                            </NavLink>
                            <NavLink
                                activePathname={pathname}
                                setPathname={setPathname}
                                className="mdl-navigation__link mdl-color-text--grey-500"
                                href={PATHNAMES.devices}
                                closeDrawer={handleDrawerButtonClick}>
                                <i className="material-icons" role="presentation">devices</i>
                                Devices
                            </NavLink>
                            <NavLink
                                activePathname={pathname}
                                setPathname={setPathname}
                                className="mdl-navigation__link mdl-color-text--grey-500"
                                href={PATHNAMES.recommendations}
                                closeDrawer={handleDrawerButtonClick}>
                                <i className="material-icons" role="presentation">queue_music</i>
                                Recommendations
                            </NavLink>
                            <NavLink
                                activePathname={pathname}
                                setPathname={setPathname}
                                className="mdl-navigation__link mdl-color-text--grey-500"
                                href={PATHNAMES.listenerPicks}
                                closeDrawer={handleDrawerButtonClick}>
                                <i className="material-icons" role="presentation">star_rate</i>
                                Listener Picks
                            </NavLink>
                            <NavLink
                                activePathname={pathname}
                                setPathname={setPathname}
                                className="mdl-navigation__link mdl-color-text--grey-500"
                                href={PATHNAMES.hosts}
                                closeDrawer={handleDrawerButtonClick}>
                                <i className="material-icons" role="presentation">person_outline</i>
                                Hosts
                            </NavLink>
                        </>
                    }
                    {authUser.roles.host && hasShows &&
                        <NavLink
                            activePathname={pathname}
                            setPathname={setPathname}
                            className="mdl-navigation__link mdl-color-text--grey-500"
                            href={PATHNAMES.payout}
                            closeDrawer={handleDrawerButtonClick}>
                            <i className="material-icons" role="presentation">savings</i>
                            Payout
                        </NavLink>
                    }
                    {(authUser.roles.admin || (authUser.roles.host && hasBroadcasts)) &&
                        <NavLink
                            activePathname={pathname}
                            setPathname={setPathname}
                            className="mdl-navigation__link mdl-color-text--grey-500"
                            href={PATHNAMES.broadcasts}
                            closeDrawer={handleDrawerButtonClick}>
                            <i className="material-icons" role="presentation">music_note</i>
                            Broadcasts
                        </NavLink>
                    }
                </nav>
            </div>
        </>
    );
}

export default NavBar;
