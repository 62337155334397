import React, { useState } from 'react';

import firebase from '../firebase';

import { MAX_BROADCAST_NOTE_LENGTH } from '../utils/constants';

function BroadcastNoteModal({ setModalOpen, selectedBroadcast, getBroadcastDetails }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [note, setNote] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const broadcastId = selectedBroadcast.id;
        firebase.addBroadcastNote(broadcastId, { noteContent: note })
            .then(() => getBroadcastDetails(broadcastId))
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
                setModalOpen(null);
            });
    }

    function onCancelClick() {
        if (!isSubmitting) setModalOpen(null);
    }

    function handleChange(e) {
        setNote(e.target.value);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <textarea
                                required
                                id="note"
                                rows="5" cols="33"
                                maxLength={MAX_BROADCAST_NOTE_LENGTH}
                                placeholder="Add note here"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name="note"
                                value={note}
                                onChange={handleChange} />
                        </div>
                        {note.length >= MAX_BROADCAST_NOTE_LENGTH && (
                            <div>
                                Max character limit reached ({MAX_BROADCAST_NOTE_LENGTH})
                            </div>
                        )}
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                type="button"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Add
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BroadcastNoteModal;
