const MODALS = {
    accountEdit: 'accountEdit',
    deleteBroadcastAudio: 'deleteBroadcastAudio',
    deleteBroadcastImage: 'deleteBroadcastImage',
    hostEdit: 'hostEdit',
    payout: 'payout',
    subscriptionLink: 'subscriptionLink',
    accountDelete: 'accountDelete',
    createBroadcast: 'createBroadcast',
    broadcastAccessLink: 'broadcastAccessLink',
    broadcastImage: 'broadcastImage',
    broadcastAudio: 'broadcastAudio',
    broadcastNote: 'broadcastNote',
};
const PATHNAMES = {
    devices: '/devices',
    home: '/',
    hosts: '/hosts',
    payout: '/payout',
    recommendations: '/recommendations',
    listenerPicks: '/listener-picks',
    broadcasts: '/broadcasts',
    newBroadcast: '/broadcasts/new',
    logout: '/logout',
};
const ADMIN_PATHNAMES = [
    PATHNAMES.devices,
    PATHNAMES.home,
    PATHNAMES.hosts,
    PATHNAMES.recommendations,
    PATHNAMES.listenerPicks,
    PATHNAMES.broadcasts,
    PATHNAMES.newBroadcast,
    PATHNAMES.logout
];
const HOST_PATHNAMES = [
    PATHNAMES.home,
    PATHNAMES.payout,
    PATHNAMES.broadcasts,
    PATHNAMES.newBroadcast,
    PATHNAMES.logout
];
const BROADCAST_DETAIL_PATH_REGEX = new RegExp(/^\/broadcasts\/(?!new)([\w\d]+)$/);
const MAX_BROADCAST_NOTE_LENGTH = 400;
const IMAGE_GUIDE_LINK = "https://docs.google.com/document/d/10tkWxnYevt2xg8sS6rQVmVCU4cm_Xu4nhYCO1dju9uM";
const BROADCAST_ACCESS_TOKEN_KEY = "broadcastAccessToken";
const SIGN_IN_TOKEN_KEY = "signInToken";

module.exports = {
    ADMIN_PATHNAMES,
    HOST_PATHNAMES,
    MODALS,
    PATHNAMES,
    BROADCAST_DETAIL_PATH_REGEX,
    MAX_BROADCAST_NOTE_LENGTH,
    IMAGE_GUIDE_LINK,
    BROADCAST_ACCESS_TOKEN_KEY,
    SIGN_IN_TOKEN_KEY
};
