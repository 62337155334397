import React, { useState } from 'react';

import firebase from '../firebase';

function BroadcastAudioModal({ setModalOpen, selectedBroadcast, getBroadcastDetails }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [audioFiles, setAudioFiles] = useState([]);

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const broadcastId = selectedBroadcast.id;
        firebase.uploadBroadcastAudio(broadcastId, { audioFiles })
            .then(() => getBroadcastDetails(broadcastId))
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
                setModalOpen(null);
            });
    }

    function onCancelClick() {
        if (!isSubmitting) setModalOpen(null);
    }

    function handleFileDrop(e) {
        e.preventDefault();

        if (e.dataTransfer.items) { // x-browser
            const files = [...e.dataTransfer.items].map(item => item.getAsFile());
            setAudioFiles([...audioFiles, ...files]);
        } else {
            const files = e.dataTransfer.files;
            setAudioFiles([...audioFiles, ...files]);
        }
    }

    function handleInputChange(e) {
        setAudioFiles([...audioFiles, ...e.target.files]);
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="modal-drag-drop" onDrop={handleFileDrop}
                        onDragOver={e => e.preventDefault()}>
                        {!audioFiles.length && (
                            <div>
                                <label className="modal-drag-drop__label" htmlFor="file">
                                    Drag audio file or click here
                                    <div>
                                        <span className="material-icons">
                                            upload_file
                                        </span>
                                    </div>
                                </label>
                                <input className="modal-drag-drop__input" id="file" type="file"
                                    accept="audio/*,.zip" onChange={handleInputChange} />
                            </div>
                        )}
                        <div>
                            {audioFiles.map(file => <div key={file.name}>{file.name}</div>)}
                        </div>
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <strong>Length:</strong> 59:30 for 60 minute broadcasts, or <br />
                        119:30 for 120 minute broadcasts
                        <br />
                        <strong>Format:</strong> 320kbps MP3
                        <br />
                        <strong>Sample rate:</strong> 44.1kHz
                        <br />
                        <strong>Recording type:</strong> Stereo
                        <br />
                        <strong>Processing:</strong> No compression or limiting
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                type="button"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting || !audioFiles.length}>
                                Upload
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BroadcastAudioModal;
