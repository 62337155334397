import React, { useState } from 'react';

import firebase from '../firebase';
import { IMAGE_GUIDE_LINK } from '../utils/constants';

const FIELDS = {
    author: 'author',
    title: 'title',
    file: 'file',
    permission: 'permission',
};

function BroadcastImageModal({ setModalOpen, selectedBroadcast, getBroadcastDetails }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [imageAuthor, setImageAuthor] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [imageFilename, setImageFilename] = useState('');
    const [imagePermission, setImagePermission] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const broadcastId = selectedBroadcast.id;
        firebase.uploadBroadcastImage({ broadcastId, imageTitle, imageAuthor, imageFile, imagePermission })
            .then(() => getBroadcastDetails(broadcastId))
            .catch(err => alert(`Something went wrong. ${err.message || ''}`))
            .finally(() => {
                setIsSubmitting(false);
                setModalOpen(null);
            });
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value, name, files } = e.target;
        switch(name) {
            case FIELDS.author:
                setImageAuthor(value);
                break;
            case FIELDS.title:
                setImageTitle(value);
                break;
            case FIELDS.permission:
                setImagePermission(value);
                break;
            case FIELDS.file:
                setImageFilename(value);
                setImageFile(files[0]);
                break;
        }
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="title">
                                Title*
                            </label>
                            <input
                                required
                                placeholder="Name of the image"
                                id="title"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.title}
                                value={imageTitle}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="author">
                                Author*
                            </label>
                            <input
                                required
                                placeholder="Owner of the image or photographer"
                                id="author"
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.author}
                                value={imageAuthor}
                                onChange={handleChange} />
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="permission">
                                Do you have permission to publish this image on the NTS website?
                            </label>
                            <br />
                            <select required name="permission" id="permission" onChange={handleChange}>
                                <option value="">Please select</option>
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>
                        </div>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="image">
                                Image*
                            </label>
                            <input
                                required
                                id="image"
                                className="mdl-textfield__input edit-modal__input"
                                type="file"
                                name={FIELDS.file}
                                value={imageFilename}
                                onChange={handleChange} />
                        </div>
                        <div style={{ marginBottom: "12px" }}>
                            The image should be 16:9 ratio (landscape) with a minimum width of 2400px and maximum height of 6000px.
                            The preferred dimension is 5600x3150px.
                            <br /><br />
                            For more info on image credits, please read guide{" "}
                            <a href={IMAGE_GUIDE_LINK} target="_blank" rel="noreferrer">here</a>.
                        </div>
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                type="button"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Upload
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BroadcastImageModal;
