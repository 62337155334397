import React from 'react';

import NavBar from './NavBar';

function UnauthorisedView({ authUser, setPathname }) {
    const subject = encodeURIComponent('NTS Desk sign-in issue');

    return (
        <div className="app mdl-layout__container">
            <div className="mdl-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
                <NavBar authUser={authUser} setPathname={setPathname}/>
                <main className="mdl-layout__content">
                    <div className="unauth-modal mdl-shadow--2dp">
                        <div className="unauth-modal__body">
                            <span className="material-icons mdl-color-text--red">
                                error
                            </span>
                            <div>
                                <div>
                                    Oops, something went wrong.
                                    <br />
                                    You are not authorised to view the requested page.
                                </div>
                                <div className="unauth-modal__contact">
                                    <a href={`mailto:support@ntslive.co.uk?subject=${subject}`}>
                                        Get in touch
                                    </a>
                                    {' '} and we&#39;ll get it sorted.
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default UnauthorisedView;
