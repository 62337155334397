import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken, signOut } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, addDoc, query, where, orderBy, limit, getDocs, documentId, updateDoc, arrayUnion, arrayRemove, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { AuthUser, HostShow, Roles, Broadcast, BroadcastAudio, BroadcastImage } from './utils/models';
import { PATHNAMES, MAX_BROADCAST_NOTE_LENGTH } from './utils/constants';
import { isProduction } from './utils/environmentHelpers';

const BROADCAST_LIMIT = 100;
const FIRESTORE_OR_LIMIT = 30; // https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries
const BROADCAST_IMAGES_EXPORT_PATH = 'broadcast_images';
const BROADCAST_AUDIO_EXPORT_PATH = 'broadcast_audio';

function expand(pathname) {
    const value = [ 'userEmails' ];
    switch(pathname) {
        case PATHNAMES.home:
            value.push('subs');
            break;
        case PATHNAMES.devices:
            value.push('deviceIds', 'deviceSettings');
            break;
        case PATHNAMES.recommendations:
            value.push('recommendations');
            break;
        case PATHNAMES.hosts:
            value.push('roles', 'shows');
            break;
    }
    return value;
}

function getFirebaseConfig() {
    if (!isProduction()) return {
        apiKey: "AIzaSyD8S9R9btaGGZLJ0bLoRgIgjrv9p4YxoQc",
        authDomain: 'nts-users-int.firebaseapp.com',
        databaseURL: 'https://nts-users-int.firebaseio.com',
        projectId: "nts-users-int",
        storageBucket: "nts-users-int.appspot.com",
    };

    return {
        apiKey: 'AIzaSyA4Qp5AvHC8Rev72-10-_DY614w_bxUCJU',
        authDomain: 'nts-ios-app.firebaseapp.com',
        databaseURL: 'https://nts-ios-app.firebaseio.com',
        projectId: "nts-ios-app",
        storageBucket: "nts-ios-app.appspot.com",
    };
}

function broadcastAccessLink(id, token) {
    if (!id) return null;

    if (!token) return `${window.location.origin}${PATHNAMES.broadcasts}/${id}`;

    return `${window.location.origin}${PATHNAMES.broadcasts}/${id}?broadcastAccessToken=${token}`;
}

function constructBroadcastFilepath(broadcastId, file, exportPath) {
    const fileId = crypto.randomUUID();
    const extension = file.name.split('.').pop();
    return `${exportPath}/${broadcastId}-${fileId}.${extension}`;
}

class Firebase {
    constructor() {
        const app = initializeApp(getFirebaseConfig());
        this.firestore = getFirestore(app);
        this.functions = getFunctions(app, 'europe-west2');
        this.auth = getAuth(app);
        this.storage = getStorage(app);
    }

    // auth methods

    signIn(email, password) {
        return signInWithEmailAndPassword(this.auth, email, password);
    }

    signOut() {
        return signOut(this.auth);
    }

    getRoles(currentUser) {
        return currentUser.getIdTokenResult()
            .then(idTokenResult => {
                if (!idTokenResult.claims) return new Roles(false, false);

                return new Roles(
                    idTokenResult.claims.admin,
                    idTokenResult.claims.host
                );
            });
    }

    onAuthStateChanged(callback) {
        return onAuthStateChanged(this.auth, firebaseUser => {
            if (!firebaseUser) return callback(null);

            this.getRoles(firebaseUser)
                .then(roles => {
                    callback(new AuthUser(firebaseUser, roles));
                });
        });
    }

    async getBroadcastAccessLink(broadcastId, email) {
        const hostUser = await this.getHostUserByEmail(email);

        if (!hostUser) {
            const response = await this.getBroadcastAccessToken(email, broadcastId);
            const token = response.data;
            return {
                broadcastAccessLink: broadcastAccessLink(broadcastId, token),
                broadcastId,
            };
        }

        const hostUserRef = hostUser.ref;
        await updateDoc(hostUserRef, {
            broadcasts: arrayUnion(broadcastId),
        });

        if (email !== this.auth.currentUser.email) return {
            broadcastAccessLink: broadcastAccessLink(broadcastId),
            broadcastId,
        };
        return { broadcastId };
    }

    async signInWithBroadcastAccessToken(accessToken, broadcastId) {
        const response = await this.getSignInToken(accessToken, broadcastId);
        const signInToken = response.data;
        return this.signInWithToken(signInToken);
    }

    signInWithToken(token) {
        return signInWithCustomToken(this.auth, token);
    }

    // cloud functions

    getCustomerInfo(customerEmail, pathname) {
        const data = {
            customerEmail,
            expand: expand(pathname),
        };
        const getCustomerInfo = httpsCallable(this.functions, 'getCustomerInfoEurope');
        return getCustomerInfo(data);
    }

    updateCustomerEmail(customerEmail, newEmail) {
        const data = {
            customerEmail,
            newEmail,
        };
        const updateCustomerEmail = httpsCallable(this.functions, 'updateCustomerEmailAddressEurope');
        return updateCustomerEmail(data);
    }

    async getDownloadHostsUrl() {
        const getUserToken = httpsCallable(this.functions, 'getUserTokenEurope');

        const resp = await getUserToken();
        const token = resp.data;
        const config = getFirebaseConfig();
        return `https://europe-west2-${config.projectId}.cloudfunctions.net/downloadHostsEurope?token=${token}`;
    }

    updateHostRole(customerEmail, isHost, shows) {
        const data = {
            customerEmail,
            isHost,
            shows,
        };
        const updateHostRole = httpsCallable(this.functions, 'updateHostRoleEurope');
        return updateHostRole(data);
    }

    async getHostShows() {
        const getHostShows = httpsCallable(this.functions, 'getHostShowsEurope');

        const response = await getHostShows();
        return response.data.map(hostShowData => new HostShow(hostShowData));
    }

    confirmShowPayout(payoutDetails, showAlias, personalDetails, amountCents) {
        const data = {
            amountCents,
            showAlias,
            payoutDetails,
            personalDetails,
        };
        const confirmShowPayout = httpsCallable(this.functions, 'confirmShowPayoutEurope');
        return confirmShowPayout(data);
    }

    confirmShowHours(hours, showAlias) {
        const data = {
            hours,
            showAlias,
        };
        const confirmShowHours = httpsCallable(this.functions, 'confirmShowHoursEurope');
        return confirmShowHours(data);
    }

    linkSubscriptionEmail(firebaseUserUid, subscriptionEmail) {
        const linkSubscriptionEmail = httpsCallable(this.functions, 'linkSubscriptionEmailEurope');
        return linkSubscriptionEmail({ firebaseUserUid, subscriptionEmail });
    }

    deleteUser(firebaseUserUid) {
        const deleteUser = httpsCallable(this.functions, 'deleteUserEurope');
        return deleteUser({ firebaseUserUid });
    }

    getListenerPicks(reportMonth) {
        const getListenerPicks = httpsCallable(this.functions, 'getListenerPicksEurope');
        return getListenerPicks({ reportMonth });
    }

    getBroadcastAccessToken(email, broadcastId) {
        const getBroadcastAccessToken = httpsCallable(this.functions, 'getBroadcastAccessTokenEurope');
        return getBroadcastAccessToken({ email, broadcastId });
    }

    getSignInToken(ntsToken, broadcastId) {
        const getSignInToken = httpsCallable(this.functions, 'getDeskAppSignInTokenEurope');
        return getSignInToken({ ntsToken, broadcastId });
    }

    // db methods

    async getAuthUserBroadcasts() {
        const docRef = doc(this.firestore, "admin_users", this.auth.currentUser.uid);
        const adminUserDoc = await getDoc(docRef);
        let { broadcasts } = adminUserDoc.data() || {};
        if (!broadcasts) return [];

        if (broadcasts.length > FIRESTORE_OR_LIMIT) {
            broadcasts = broadcasts.slice(broadcasts.length - FIRESTORE_OR_LIMIT, broadcasts.length);
        }
        const q = query(
            collection(this.firestore, "broadcasts"),
            where(documentId(), 'in', broadcasts),
            orderBy('created_at', 'desc'),
            limit(BROADCAST_LIMIT)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => {
            return new Broadcast(doc.id, doc.data());
        });
    }

    async getAdminUserEmailsByBroadcastId(broadcastId) {
        const q = query(
            collection(this.firestore, "admin_users"),
            where('broadcasts', 'array-contains', broadcastId)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) return [];

        return querySnapshot.docs.reduce((emails, doc) => {
            const { user_email } = doc.data();
            if (user_email) emails.push(user_email);
            return emails;
        }, []);
    }

    async getBroadcastDetails(broadcastId) {
        const userEmails = await this.getAdminUserEmailsByBroadcastId(broadcastId);
        const docRef = doc(this.firestore, "broadcasts", broadcastId);
        const broadcastDoc = await getDoc(docRef);
        const broadcastData = broadcastDoc.data();
        const broadcastImages = await this.getBroadcastImages(broadcastData.images);
        const broadcastAudios = await this.getBroadcastAudios(broadcastData.audio_filepaths);
        return new Broadcast(broadcastId, broadcastData, userEmails, broadcastImages, broadcastAudios);
    }

    async getRecentBroadcasts() {
        const q = query(
            collection(this.firestore, "broadcasts"),
            orderBy('created_at', 'desc'),
            limit(BROADCAST_LIMIT)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) return [];

        return querySnapshot.docs.map(doc => {
            return new Broadcast(doc.id, doc.data());
        });
    }

    async writeBroadcast(title) {
        const broadcastsRef = collection(this.firestore, 'broadcasts');
        const broadcastDocRef = await addDoc(broadcastsRef, {
            created_at: serverTimestamp(),
            title: title || '',
        });

        return broadcastDocRef.id;
    }

    updateBroadcast(broadcastId, data) {
        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        return updateDoc(broadcastDocRef, data);
    }

    async getHostUserByEmail(email) {
        const adminUsersRef = collection(this.firestore, 'admin_users');
        const q = query(adminUsersRef, where('user_email', '==', email));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) return null;

        const doc = querySnapshot.docs[0];
        const { host } = doc.data();
        if (!host) return null;

        return doc;
    }

    async createBroadcast(email, title) {
        const newBroadcastId = await this.writeBroadcast(title);
        if (!email) return ({ broadcastId: newBroadcastId });

        return this.getBroadcastAccessLink(newBroadcastId, email);
    }

    addBroadcastNote(broadcastId, { noteContent }) {
        if (!noteContent
            || typeof noteContent !== 'string'
            || noteContent.length > MAX_BROADCAST_NOTE_LENGTH) throw new Error('Invalid note input.');

        const noteItem = {
            content: noteContent,
            created_by: this.auth.currentUser.email,
        };

        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        return updateDoc(broadcastDocRef, {
            notes: arrayUnion(noteItem),
        });
    }

    async getBroadcastImages(images) {
        if (!images) return [];

        const imagesWithUrl = await Promise.all(
            images.map(async image => {
                let url = null;
                if (image.filepath) {
                    try {
                        url = await getDownloadURL(ref(this.storage, image.filepath));
                    } catch(e) {
                        console.log("Unable to fetch image url");
                    }
                }
                return new BroadcastImage(image, url);
            })
        );
        return imagesWithUrl.reverse();
    }

    async getBroadcastAudios(filepaths) {
        if (!filepaths) return [];

        const audioItems = await Promise.all(
            filepaths.map(async filepath => {
                const url = await getDownloadURL(ref(this.storage, filepath));
                return new BroadcastAudio(filepath, url);
            })
        );
        return audioItems.reverse();
    }

    async uploadBroadcastImage({ broadcastId, imageTitle, imageAuthor, imageFile, imagePermission }) {
        const filepath = constructBroadcastFilepath(broadcastId, imageFile, BROADCAST_IMAGES_EXPORT_PATH);
        const storageRef = ref(this.storage, filepath);
        await uploadBytes(storageRef, imageFile);

        const image = {
            filepath,
            source: this.auth.currentUser.email,
            author: imageAuthor,
            title: imageTitle,
            permission: imagePermission,
        };

        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        return updateDoc(broadcastDocRef, {
            images: arrayUnion(image),
        });
    }

    async deleteBroadcastImage({ broadcastId, image }) {
        const storageRef = ref(this.storage, image.filepath);
        await deleteObject(storageRef);

        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        const broadcastDoc = await getDoc(broadcastDocRef);
        const broadcastData = broadcastDoc.data();
        const broadcastImages = broadcastData.images || [];
        const selectedImage = broadcastImages.find(broadcastImage => broadcastImage.filepath === image.filepath);
        if (!selectedImage) throw new Error('Selected image not found!');

        return updateDoc(broadcastDocRef, {
            images: arrayRemove(selectedImage),
        });
    }

    async deleteBroadcastAudio({ broadcastId, audio }) {
        const storageRef = ref(this.storage, audio.filepath);
        await deleteObject(storageRef);

        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        return updateDoc(broadcastDocRef, {
            audio_filepaths: arrayRemove(audio.filepath),
        });
    }

    async uploadBroadcastAudio(broadcastId, { audioFiles }) {
        const filepaths = [];
        const storagePromises = audioFiles.map(audioFile => {
            const filepath = constructBroadcastFilepath(broadcastId, audioFile, BROADCAST_AUDIO_EXPORT_PATH);
            filepaths.push(filepath);
            const storageRef = ref(this.storage, filepath);
            return uploadBytes(storageRef, audioFile);
        });

        await Promise.all(storagePromises);

        const broadcastDocRef = doc(this.firestore, 'broadcasts', broadcastId);
        return updateDoc(broadcastDocRef, {
            audio_filepaths: arrayUnion(...filepaths),
        });
    }
}

export default new Firebase();
